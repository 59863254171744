/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~devextreme/dist/css/dx.light.css";

@import "src/theme/variables.scss";
@import "src/theme/dx-dark.scss";
@import "~@ng-select/ng-select/themes/material.theme.css";


.announ-title {
  font-size: 1.2 rem;
  text-align: start;
}

.dx-tag-content {
  background-color: $primary-color !important;
  color: #ffffff !important;
  border-radius: 7px !important;
}

.dx-tag-remove-button::after, .dx-tag-remove-button::before {
  background-color: #ffffff !important;
}

.ng-select .ng-select-container {
  min-height: 35.5px !important;
  .ng-value-container {
    border-top: none !important;
  }
  &::after {
    border-bottom: none !important;
  }
}
@media screen and (max-width: 767px) {
  .announ-title {
    font-size: 0.9rem;
  }
}

.selection-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  & span {
    margin: 0px 5px;
    font-size: smaller;
  }
  & .selection-input {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 2px;
    & ion-input {
      line-height: 10px;
      font-size: 12px;
      & .native-input {
        padding: 5px;
      }
    }
  }
}

.p-3 {
  padding: 10px !important;
}
.d-flex {
  display: flex !important;
}
.h-100 {
  height: 100% !important;
}
.mr-1 {
  margin-right: 5px;
}
.ml-1 {
  margin-left: 5px;
}

ion-grid {
  overflow-y: auto !important;
}

.w-100 {
  width: 100% !important;
}

i:hover {
  cursor: pointer;
}

ion-tab-button.tab-selected {
  background: $secondary-color;
  color: white;
}

@media screen and (max-width: 1000px) {
  .hide-1000 {
    display: none !important;
  }
}

.bg-passive {
  background-color: #ff6f6f;
}

.bg-active {
  background-color: #a2ffa2;
}

.input-group {
  margin-top: 1rem;
  & ::part(native) {
    background-color: transparent;
    border-radius: 15px;
    border: 1px rgba($color: #ffffff, $alpha: 0.5) solid;
    color: white;
  }
  .label {
    margin-bottom: 10px;
    color: white;
  }
}
.text-center {
  justify-content: center !important;
  display: flex;
  width: 100%;
  text-align: center !important;
}
.otp-modal .modal-wrapper {
  background-color: #4c52bc;
  width: 60%;
  max-width: 400px;
  height: 55%;
  border-radius: 10px;
}

.full-screen {
  .modal-wrapper {
    width: 100%;
    height: 100%;
  }
}

.user-segment-modal .modal-wrapper {
  height: 100%;
  max-height: 600px;
  max-width: 90%;
  width: 75%;
}

.kerzz-input {
  & ion-item.input {
    height: 40px;
  }
  &:not(.no-border) ion-item {
    border: 1px solid black;
    border-radius: 10px;
  }
  & ion-text {
    font-size: smaller;
  }

  & ion-label {
    font-size: small;
    font-weight: bold;
    color: #000000;
  }
  & ion-input {
    --padding-top: 0px !important;
  }
  ion-select:not(.phone-select) {
    width: 100% !important;
  }
  & ion-select {
    --padding-top: 0px !important;
  }

  & > :not(app-orwi-select) .dx-texteditor.dx-editor-outlined {
    border-radius: 10px !important;
    border: 1px solid #000 !important;
    height: 40px !important;
  }

  & .ng-select .ng-select-container {
    border-radius: 10px !important;
    border: 1px solid #000 !important;
    height: 40px !important;
    padding: 0 10px;
  }
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.magaza-list-modal {
  & .modal-wrapper {
    width: 90%;
    height: 60%;
    border-radius: 10px;
    max-width: 400px;
  }
}

.sync-error-modal {
  & .modal-wrapper {
    width: 90%;
    height: 60%;
    border-radius: 10px;
    max-width: 800px;
  }
}

.kerzz-modal {
  & .modal-wrapper {
    // background-color: #fff;
    width: 90%;
    height: 75%;
    border-radius: 10px;
    max-width: 900px;
  }
}

.list-modal {
  @extend .kerzz-modal;
  & .modal-wrapper {
    max-width: 400px;
  }
}

.note-container {
  padding: 0 10px;
  width: 100%;
  & kerzz-button {
    margin: 0 5px;
  }
}

.pagination-container {
  display: flex;
  justify-content: flex-end;

  & div {
    padding: 10px;
    background-color: #eee;
    margin: 0 5px;
    border-radius: 5px;
    width: 40px;
    align-items: center;
    display: flex;

    justify-content: center;
    &:hover {
      &:not(.disable) {
        cursor: pointer;
      }
    }
  }

  & .arrow {
    padding: 5px;

    &.disable {
      background-color: gray;
      color: #ccc;
    }
  }

  & .page-num {
    &.active-page {
      background-color: $primary-color;
      color: white;
    }
  }
}

.kerzz-qr-modal {
  & .modal-wrapper {
    background-color: #fff;
    width: 90%;
    height: 90%;
    border-radius: 10px;
    max-width: 350px;
    max-height: 550px;
  }
}

.orwi-table-action-modal {
  & .modal-wrapper {
    background-color: #fff;
    width: 90%;
    height: 90%;
    border-radius: 10px;
    max-width: 350px;
    max-height: 350px;
  }
}

.flex-column {
  flex-direction: column !important;
}

ion-toggle {
  height: 20px !important;
  --handle-width: 15px !important;
  width: 35px !important;
}

.header-btn-desc {
  text-transform: capitalize;
  margin-left: 5px;
}

ion-router-outlet#page {
  & .ion-page {
    padding: 10px;
    padding-left: 20px;
    overflow-y: auto;
    justify-content: flex-start;
  }
}

.d-none {
  display: none !important;
}

span.btn-desc {
  text-transform: capitalize !important;
}

.vertical-center {
  vertical-align: middle !important;
}

@media screen and (max-width: 466px) {
  ion-button {
    span.btn-desc {
      display: none !important;
    }
  }
}

.ng-select-disabled {
  .ng-select-container {
    background-color: #e1e1e1;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.btn-title {
  text-transform: capitalize;
  margin-left: 5px;
}

.dx-datagrid-borders > .dx-datagrid-rowsview.dx-last-row-border td {
  text-align: center;
  vertical-align: middle;
}

.listview-item {
  font-size: 12px !important;
  & .btn-desc {
    margin-left: 5px;
    font-size: 11px !important;
  }
}

ion-item.listview-item {
  &::part(native):hover {
    cursor: pointer;
    background-color: #ccc;
  }
}
.cursor-pointer:hover {
  cursor: pointer !important;
}

.profile-area {
  margin-right: 5px;
}

.profile-img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  border-radius: 50%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
  text-align: right !important;
}

@media screen and (max-width: 767px) {
  .header-btn-desc {
    display: none !important;
  }
  .otp-modal .modal-wrapper {
    width: 90%;
    height: 55%;
  }
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px rgb(255 255 255/ 75%) solid;
  margin-right: 10px;
  background-color: #5ec1a7;
}

.ok-img {
  @extend .circle;
  background-color: #5ec1a7;
}

.error-img {
  @extend .circle;
  background-color: #e84c89;
}

.bg-waiting {
  background-color: blue;
  color: white;
}

.bg-complete {
  background-color: yellowgreen;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// DASHBOARD STYLES
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px rgb(255 255 255/ 75%) solid;
  margin-right: 10px;
  background-color: #5ec1a7;
}

.success-circle {
  @extend .circle;
  background-color: #5ec1a7;
}

.error-circle {
  @extend .circle;
  background-color: #e84c89;
}

.mission-list {
  & span {
    font-size: 12px;
  }
}

.card-title-small {
  font-size: 70%;
  font-weight: 600;
}

.card-subtitle-small {
  height: 30px;
  margin: 5px 0px;
  font-weight: bold;
}

.total-order-progress {
  height: 20px;
  border-radius: 10px;
  &::part(progress) {
    background-color: #f0d74a;
    border-radius: 10px;
  }

  &::part(track) {
    background-color: #4c52bc;
  }
}

.dot-delivery {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;

  background-color: #f0d74a;
  border: none;
}
.dot-pickup {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;

  background-color: #4c52bc;
  border: none;
}

.dot-container {
  margin: 10px 0px;
}

// DASHBOARD STYLES END

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

body.dark {
  .kerzz-input {
    &:not(.no-border) ion-item {
      border: 1px solid #424242;
      border-radius: 10px;
    }
    & ion-label {
      color: #f4f4f4;
    }
    & .dx-texteditor.dx-editor-outlined {
      border: 1px solid #424242 !important;
    }
    & .ng-select .ng-select-container {
      border: 1px solid #424242 !important;
      color: #f4f4f4!important;
      background-color: #1e1e1e;
    }
    & .ng-select .ng-select-container .ng-arrow, .ng-select .ng-select-container .ng-clear{
      color: #f4f4f4!important;
    }
  }
  ion-modal b{
    color: #f4f4f4;
  }
  ng-select .ng-value-container input{
    color: #f1f1f1;
  }
  .orwi-table-action-modal .modal-wrapper{
    background-color: #121212;
  }
  app-orwi-table-action-change h4{
    color: #f1f1f1;
  }
  .navCardWrapper{
    .navCard{   
      .frontFace{
          background-color: #1f1f1f!important;

          .iconWrapper i{
            color: #cacfe7!important;
        }
      }
      .backFace{
          background-color: #1f1f1f!important;
      }

    }
  }
}

body.scanner-active {
  --background: transparent;
  --ion-background-color: transparent;

  ion-header {
    display: none !important;
  }

  div:has(video#video) {
    z-index: -1 !important;
  }
}


.dx-datagrid-content .dx-datagrid-table .dx-row > td, .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: middle !important;
}

.list-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  & span.item-label {
    font-size: 12px;
  }
}

.dx-widget.numberField input {
  text-align: right;
}